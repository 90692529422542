<template>
  <Cursussenoverzicht />
</template>

<script>
import Cursussenoverzicht from "@/components/cursussen/Cursussenoverzicht";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Cursussenoverzicht,
  },
  metaInfo: {
    title: "Cursussenoverzicht | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
