<template>

    <v-row style="margin-left:5px;margin-bottom: 5px;">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
         
          <v-btn
         
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
          Status wijzigen
          </v-btn>
        </template>
        <v-card>
          <v-card-title style="font-size: 14px;">
           Status wijzingen van de bestelling
          </v-card-title>
          <v-card-text>

            <label>Selecteer status</label>
                <v-autocomplete
                  dense
                  outlined
                  :items="statussen.flat()"
                  label="Status selecteren"
                  v-model="form.status"
                  item-text="naam"
                  item-value="naam"
                ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="dialog = false"
            >
              Annuleren
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="updateStatusCursus()"
        
            >
              Wijzingen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>


<script>
import apiRodekruis from "../api/apiRodekruis";
import apiStatus from "../api/apiStatus";
export default {
  data: () => ({
    statussen:[],
    orderDetails: {},
    form:{
        status:"",
    }
  }),
  async mounted() {
    await this.statussenOphalen();
  },
  methods: {
    statussenOphalen(){
      apiStatus.haalStatusOp().then((data) => {
      this.statussen = data;
    });
    },

    async updateStatusCursus() {
      try {
        const id = this.$route.params.id;
        const response = await apiRodekruis.updateRodekruisOrder(
          id,
          { intern: this.form.status }
        );
        console.log(response);
        this.$swal.fire("Yes!", "De status is gewijzigd!", "success");
        await this.statussenOphalen();
        this.$emit('statusUpdated'); 
        this.dialog = false
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    
  },
};
</script>
