<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          Overzicht Cursussen
          <v-spacer></v-spacer>
          <v-text-field
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Opzoeken"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
          <div style="text-align: right">
            <div style="margin-bottom: 2rem; margin-top: 1rem">
              <v-btn color="primary" @click="addTraining()">
                Cursus toevoegen
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" style="margin-left: 10px">
                    Exporteren
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in exportOptions"
                    :key="item.text"
                    @click="exportData(item.type)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="isFetching ? [] : cursussen"
          :search="search"
          :loading="isFetching"
          disable-pagination
          :fixed-header="true"
          height="75vh"
          :hide-default-footer="true"
          class="elevation-1"
          id="my-kavels"
          cellClass="printable"
        >
          <template v-slot:top></template>
          <template v-slot:[`item.prijs`]="{ value }">€ {{ value }}</template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-icon small color="red" @click="deleteTraining(item.id)">
              mdi-delete
            </v-icon>
            <v-icon small color="green" @click="openSingleTraining(item.id)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="page"
              class="my-4 pb-4"
              :length="totalPages"
              :total-visible="7"
              @input="onPageChange"
            ></v-pagination>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "jspdf-autotable";
import apiTraining from "../api/apiTraining";

function debounce(func, delay) {
  let debounceTimer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}
export default {
  data: () => ({
    page: 1,
    isFetching: false,
    exportingType: null,
    cursussen: [],
    dialogVisible: false,
    search: "",
    items: [],
    filteredItems: [],
    statusFilter: "",
    statusOptions: [],
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
    headers: [
      {
        text: "Artikelnummer",
        value: "artikelnummer",
        sortable: true,
      },
      {
        text: "Titel",
        value: "titel",
        sortable: true,
      },
      {
        text: "Categorie",
        value: "categorie",
        sortable: true,
      },
      {
        text: "Aantal deelnemers",
        value: "aantaldeelnemers",
        sortable: true,
      },
      {
        text: "Prijs",
        value: "prijs",
        sortable: true,
      },
      {
        text: "Actions",
        value: "Actions",
        sortable: false,
        formatter: (value) => `€${value}`,
      },
    ],
    totalPages: 1,
  }),

  async mounted() {
    this.isFetching = true;
    await this.getTrainingen(this.page);
    //await this.getPageNumber();
  },

  computed: {
    modifiedHeaders() {
      return this.headers;
    },

    modifiedHeadersWithoutActions() {
      return this.headers.filter((header) => header.value !== "Actions");
    },
    paginatieReeks() {
      let reeks = [];
      const paginaBuffer = 2;
      const ellipses = "...";
      const startPagina = Math.max(this.page - paginaBuffer, 1);
      const eindPagina = Math.min(this.page + paginaBuffer, this.totalPages);

      if (startPagina > 1) {
        reeks.push(1);
        if (startPagina > 2) reeks.push(ellipses);
      }

      for (let i = startPagina; i <= eindPagina; i++) {
        reeks.push(i);
      }

      if (eindPagina < this.totalPages) {
        if (eindPagina < this.totalPages - 1) reeks.push(ellipses);
        reeks.push(this.totalPages);
      }

      return reeks;
    },
  },
  watch: {
    search(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFetching = true;
        this.queryHandler();
      }
    },
  },
  methods: {
    addTraining() {
      this.$router.push("/Cursustoevoegen");
    },
    async exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.customers);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "cursussen.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF();
        const tableData = [];
        this.filteredItems.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("cursussen.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-kavels").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
    queryHandler: debounce(function() {
      this.page = 1;
      this.getTrainingen(1);
    }, 500),
    async getTrainingen(pageNumber) {
      this.isFetching = true;
      await apiTraining
        .getTrainingen(pageNumber, this.search)
        .then((data) => {
          this.cursussen = data.trainingen;
          this.totalPages = data.totalPages;
        })
        .catch((error) => {
          console.error("Error fetching Cursus data:", error);
          // Handle error (e.g., update UI state to show an error message)
        })
        .finally(() => {
          this.isFetching = false;
        });
    },

    onPageChange(pageNumber) {
      this.page = pageNumber;
      this.getTrainingen(pageNumber);
    },

    openSingleTraining(id) {
      this.$router.push(`/Cursusbekijken/${id}`);
    },

    async deleteTraining(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze Cursus wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          await apiTraining.deleteTraining(id);
          await this.getTrainingen(this.page);
          this.$swal.fire(
            "Verwijderd",
            "De Cursus is succesvol verwijderd",
            "success"
          );
        } catch (error) {
          console.error("Fout bij het verwijderen van de Cursus:", error);
        }
      }
    },
  },
};
</script>
<style scoped>
.hide-actions {
  display: none;
}
@media print {
  .hide-print {
    display: none !important;
  }
}
</style>
