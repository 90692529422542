<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title> Orderdetails - {{ orderDetails.naam }} </v-card-title>
        <v-card-text>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Bestelinformatie</v-list-item-title>

                <v-list-item-subtitle
                  >Order ID: {{ orderDetails.id }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Besteldatum:
                  {{ orderDetails.Besteldatum }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Cursus: {{ orderDetails.artnr }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>NAW gegevens</v-list-item-title>

                <v-list-item-subtitle
                  >Naam: {{ orderDetails.naam }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Email: {{ orderDetails.email }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Telefoonnummer:
                  {{ orderDetails.telefoonnummer }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Adres: {{ orderDetails.adres }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Cursus informatie</v-list-item-title>

                <v-list-item-subtitle
                  >Cursus: {{ orderDetails.cursus }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Locatie: {{ orderDetails.locatie }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Stad: {{ orderDetails.stad }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Startdatum: {{ orderDetails.start }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Financiele informatie</v-list-item-title>

                <v-list-item-subtitle
                  >Prijs: €{{ orderDetails.prijs }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Status: {{ orderDetails.status }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Intern: {{ orderDetails.intern }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Status bestelling</v-list-item-title>

                <v-list-item-subtitle
                  >Status (wordt bepaald door de applicatie):
                  <b> {{ orderDetails.status }}</b></v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Intern:<b style="color: coral;">
                    {{ orderDetails.intern }}</b
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            dark
            style="margin-bottom:15px"
            @click="deleteOrder()"
          >
            Bestelling verwijderen
          </v-btn>
          <updateOrder @statusUpdated="fetchOrderDetails" />
          <v-btn
            @click="goBackWithRouter"
            text
            style="color:white"
            color="warning"
            >Ga terug</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import updateOrder from "./updateOrder.vue";
import RodeKruis from "../api/apiRodekruis";
import apiStatus from "../api/apiStatus";

export default {
  components: {
    updateOrder,
  },
  data: () => ({
    statussen: [],
    orderDetails: {},
  }),
  async mounted() {
    await this.fetchOrderDetails();
  },
  methods: {
    async fetchOrderDetails() {
      const id = this.$route.params.id;
      await RodeKruis.getSinlegeRodekruisOrder(id).then((data) => {
        this.orderDetails = data;
      });
    },

    statussenOphalen() {
      apiStatus.haalStatusOp().then((data) => {
        this.statussen = data;
      });
    },
    goBackWithRouter() {
      this.$router.go(-1);
    },

    async deleteOrder() {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze order wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          const id = this.$route.params.id;
          await RodeKruis.deleteorder(id);
          await this.fetchOrderDetails();
          this.$swal.fire(
            "Verwijderd",
            "De Order is succesvol verwijderd",
            "success"
          );
          this.$router.push("Bestellingen");
        } catch (error) {
          console.error("Fout bij het verwijderen van de order:", error);
        }
      }
    },
  },
};
</script>
