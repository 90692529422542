<!-- eslint-disable no-self-assign -->
<template>
  <v-container style="padding-top:2rem">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  dense
                  placeholder="Host"
                  v-model="basicAuth.host"
                  outlined
                ></v-text-field>

                <v-text-field
                  dense
                  placeholder="Poort"
                  v-model="basicAuth.port"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  placeholder="Gebruikersnaam"
                  v-model="basicAuth.username"
                  outlined
                ></v-text-field>

                <v-text-field
                  dense
                  placeholder="Wachtwoord"
                  v-model="basicAuth.password"
                  outlined
                ></v-text-field>

                <v-text-field
                  dense
                  placeholder="Emailadres "
                  v-model="basicAuth.mail"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn color="secondary" @click="addMailserver()" block>
                  Opslaan
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiMail from "../api/apiMail";
export default {
  data() {
    return {
      basicAuth: {
        host: "",
        username: "",
        port: "",
        password: "",
        mail: "",
        authType: "basic",
      },
    };
  },
  async mounted() {
    const basicAuthData = await apiMail.getBasicAuth();
    if (this.areAllFieldsFilled(basicAuthData)) {
      this.basicAuth = basicAuthData;
      this.basicAuthLoaded = true;
      this.dataLoaded = true;
    }
  },
  methods: {
    areAllFieldsFilled(obj) {
      return Object.values(obj).every((value) => value !== "");
    },

    async addMailserver() {
      try {
        if (!this.areAllFieldsFilled(this.basicAuth)) {
          this.$swal.fire(
            "Foutmelding",
            "Alle velden moeten ingevuld zijn",
            "error"
          );
          return;
        }
        const response = await apiMail.addMailServer(this.basicAuth);
        console.log(response);
        this.$swal.fire("Yes!", "Authenticatiegegevens opgeslagen", "success");
        this.isEditing = !this.isEditing;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 10px;
}
</style>
