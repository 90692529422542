import { API } from "./api.js";
export default {
  async startSynch() {
    try {
      const response = await API.post(
        "/api/rest/v2/pdfcontroller/start/pdfsynch"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
