<template>
  <div>     
        <v-card-title>
          Bestellingen
          <v-spacer></v-spacer>
          <v-text-field
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Opzoeken"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
          <div style="text-align: right">
            <v-btn @click="startSynch()" dense> Start ophalen orders</v-btn>
            <div style="margin-bottom: 2rem; margin-top: 1rem">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn dark v-on="on" style="margin-left: 10px">
                    Exporteren
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in exportOptions"
                    :key="item.text"
                    @click="exportData(item.type)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="isFetching ? [] : orders"
          :search="search"
          :loading="isFetching"
          disable-pagination
          :fixed-header="true"
          height="75vh"
          :hide-default-footer="true"
          class="elevation-1"
          id="my-kavels"
          cellClass="printable"
        >
          <template v-slot:[`item.startdatum`]="{ item }">
            {{ formatDate(item.startdatum) }}
          </template>
          <template v-slot:[`item.besteldatum`]="{ item }">
            {{ formatDate(item.besteldatum) }}
          </template>
          <template v-slot:[`item.createDate`]="{ item }">
            {{ formatDate(item.createDate) }}
          </template>
          <template v-slot:[`item.rodekruisorder`]="{ item }">
            {{ item.rodekruisorder ? "Ja" : "Nee" }}
          </template>

          <template v-slot:top></template>
          <template v-slot:[`item.price`]="{ value }">€{{ value }}</template>
          <template v-slot:[`item.Actions`]="{ item }">
            <!-- <v-icon small color="red" @click="deleteOrder(item.id)">
              mdi-delete
            </v-icon> -->
            <v-icon small color="green" @click="openSingleOrder(item.id)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="page"
              class="my-4 pb-4"
              :length="totalPages"
              :total-visible="7"
              @input="onPageChange"
            ></v-pagination>
          </template>
        </v-data-table>
     

  </div>
</template>

<script>
import apiPdf from "../api/apiPdf";
import RodeKruis from "../api/apiRodekruis";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "jspdf-autotable";
import apiStatus from "../api/apiStatus";
function debounce(func, delay) {
  let debounceTimer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}
export default {
  data: () => ({
    page: 1,
    isFetching: false,
    exportingType: null,
    orders: [],
    statussen: [],
    search: "",
    items: [],
    filteredItems: [],
    statusFilter: "",
    statusOptions: [],
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
    headers: [
      {
        text: "Besteldatum",
        value: "besteldatum",
        sortable: true,
      },
      {
        
        text: "Cursus",
        value: "cursus",
        sortable: true,

    
      },
      {
        text: "Naam",
        value: "naam",
        sortable: true,
      },

      {
        text: "Email",
        value: "email",
        sortable: true,
      },
      {
        text: "Telefoonnummer",
        value: "telefoonnummer",
        sortable: true,
      },
      {
        text: "Status",
        value: "status",
        sortable: true,
      },

      {
        text: "Status intern",
        value: "intern",
        sortable: true,
      },

      {
        text: "Cursusdatum",
        value: "startdatum",
        sortable: true,
      },
      {
        text: "Betaalstatus",
        value: "betaalStatus",
        sortable: true,
      },
      {
        text: "Rodekruisorder",
        value: "rodekruisorder",
        sortable: true,
      },

      {
        text: "Aanmaakdatum",
        value: "createDate",
        sortable: true,
      },
      {
        text: "Actions",
        value: "Actions",
        sortable: false,
        formatter: (value) => `€${value}`,
      },
    ],
    totalPages: 1,
  }),

  async mounted() {
    this.fetchingStatus();
    this.isFetching = true;
    await this.getOrders(this.page);
    // await this.getPageNumber();
  },

  computed: {
    modifiedHeaders() {
      return this.headers;
    },

    modifiedHeadersWithoutActions() {
      return this.headers.filter((header) => header.value !== "Actions");
    },
    paginatieReeks() {
      let reeks = [];
      const paginaBuffer = 2;
      const ellipses = "...";
      const startPagina = Math.max(this.page - paginaBuffer, 1);
      const eindPagina = Math.min(this.page + paginaBuffer, this.totalPages);

      if (startPagina > 1) {
        reeks.push(1);
        if (startPagina > 2) reeks.push(ellipses);
      }

      for (let i = startPagina; i <= eindPagina; i++) {
        reeks.push(i);
      }

      if (eindPagina < this.totalPages) {
        if (eindPagina < this.totalPages - 1) reeks.push(ellipses);
        reeks.push(this.totalPages);
      }

      return reeks;
    },
  },
  watch: {
    search(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isFetching = true;
        this.queryHandler();
      }
    },
  },
  methods: {
    formatDate(isoString) {
      const date = new Date(isoString);
      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    openSingleOrder(id) {
      this.$router.push(`/Bestellingbekijken/${id}`);
    },
    async fetchingStatus() {
      apiStatus.haalStatusOp().then((data) => {
        this.statussen = data;
      });
    },

    async exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.customers);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Kavels.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF();
        const tableData = [];
        this.filteredItems.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("Kavels.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-kavels").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
    queryHandler: debounce(function() {
      this.page = 1;
      this.getOrders(1);
    }, 500),
    async getOrders(pageNumber) {
      this.isFetching = true;
      await RodeKruis.getRodekruisOrders(pageNumber, this.search)
        .then((data) => {
          this.orders = data.trainingen;
          this.totalPages = data.totalPages;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },

    onPageChange(pageNumber) {
      this.page = pageNumber;
      this.getOrders(pageNumber);
    },
    async startSynch() {
      try {
        const response = await apiPdf.startSynch();
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        await this.getOrders(this.page);
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
<style scoped>
.hide-actions {
  display: none;
}
@media print {
  .hide-print {
    display: none !important;
  }
}
</style>
