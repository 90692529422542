var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_vm._v(" Bestellingen "),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","append-icon":"mdi-magnify","label":"Opzoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"dense":""},on:{"click":function($event){return _vm.startSynch()}}},[_vm._v(" Start ophalen orders")]),_c('div',{staticStyle:{"margin-bottom":"2rem","margin-top":"1rem"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"10px"},attrs:{"dark":""}},on),[_vm._v(" Exporteren "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.exportOptions),function(item){return _c('v-list-item',{key:item.text,on:{"click":function($event){return _vm.exportData(item.type)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.isFetching ? [] : _vm.orders,"search":_vm.search,"loading":_vm.isFetching,"disable-pagination":"","fixed-header":true,"height":"75vh","hide-default-footer":true,"id":"my-kavels","cellClass":"printable"},scopedSlots:_vm._u([{key:"item.startdatum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startdatum))+" ")]}},{key:"item.besteldatum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.besteldatum))+" ")]}},{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createDate))+" ")]}},{key:"item.rodekruisorder",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rodekruisorder ? "Ja" : "Nee")+" ")]}},{key:"top",fn:function(){return undefined},proxy:true},{key:"item.price",fn:function(ref){
var value = ref.value;
return [_vm._v("€"+_vm._s(value))]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.openSingleOrder(item.id)}}},[_vm._v(" mdi-eye ")])]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"my-4 pb-4",attrs:{"length":_vm.totalPages,"total-visible":7},on:{"input":_vm.onPageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }