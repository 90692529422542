
<template>
  <WebshopOrders />
</template>

<script>
import WebshopOrders from "@/components/Webshop/WebshopOrders";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    WebshopOrders,
  },
  metaInfo: {
 title: "Webwinkel bestellingen",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>