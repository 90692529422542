import { API } from "./api.js";
export default {
  async getRodekruisOrders() {
    try {
      const response = await API.get("/api/rest/v2/rodekruis");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSinlegeRodekruisOrder(id) {
    try {
      const response = await API.get(`/api/rest/v2/rodekruis/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateRodekruisOrder(id, intern) {
    try {
      const response = await API.put(`/api/rest/v2/rodekruis/${id}`,intern);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteorder(id) {
    try {
      const response = await API.delete(`/api/rest/v2/rodekruis/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
