<template>
  <v-container>
    <v-btn
      v-if="isFolderSelected"
      style="color:white;"
      color="#147D81"
      @click="backToMainFolders"
    >
      Terug naar Hoofdmappen
    </v-btn>

    <v-row>
      <v-col cols="3" v-if="!isFolderSelected">
        <h4>Documenten</h4>
      </v-col>
      <v-col cols="9" class="actions" v-if="!isFolderSelected">
        <div class="action">
          <v-btn
            rounded
            style="color:white;background-color:#147D81"
            @click="uploadDialog = !uploadDialog"
          >
            <v-icon>mdi-file-upload</v-icon>Document uploaden
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!isFolderSelected">
      <v-col
        v-for="folder in folders"
        :key="folder.name"
        cols="12"
        md="6"
        lg="4"
      >
        <v-card class="folder-card" @click="openFolder(folder.name)">
          <v-icon x-large color="yellow">mdi-folder</v-icon>
          <span class="folder-name" style="font-size: 16px">{{
            folder.name
          }}</span>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <single-folder
          v-if="selectedFolder.name"
          :folder-name="selectedFolder.name"
          :folder-files="selectedFolder.files"
          @openFolder="openFolder"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="uploadDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Document Uploaden
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="selectedFile"
                  label="Bestand kiezen"
                  prepend-icon="mdi-paperclip"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectedFolderName"
                  :items="folderOptions"
                  label="Selecteer een map"
                  prepend-icon="mdi-folder"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="uploadDialog = false"
            >Annuleren</v-btn
          >
          <v-btn color="blue darken-1" text @click="handleFileUpload"
            >Uploaden</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { API } from "../api/api";
import apiFolders from "../api/apiFolders";
import singleFolder from "./SingleFolder.vue";
export default {
  components: {
    singleFolder,
  },
  data() {
    return {
      uploadDialog: false,
      selectedFile: null,
      selectedFolderName: "",
      folderOptions: ["Facturen", "Instructies", "In de winkel"],

      folders: [],
      selectedFolder: {
        name: "",
        files: [],
      },
      isFolderSelected: false,
    };
  },
  async mounted() {
    try {
      const data = await apiFolders.getDirectories();
      this.folders = data;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async handleFileUpload() {
      if (!this.selectedFile || !this.selectedFolderName) {
        alert("Selecteer een bestand en een map.");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      this.isUploading = true;
      try {
        await API.post(
          `/api/rest/v2/Trainingafbeeldingen/upload/${this.selectedFolderName}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        this.$swal.fire("Gelukt!", "Bestand is toegevoegd!", "success");
      } catch (error) {
        console.error("Uploadfout:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.$swal.fire("Fout", error.response.data.error.message, "error");
        } else {
          this.$swal.fire("Gelukt", "Het uploaden is gelukt", "success");
        }
      } finally {
        this.uploadDialog = false;
        this.selectedFile = null;
        this.selectedFolderName = "";
        this.isUploading = false;
      }
    },

    selectFolder(subFolderName) {
      const newPath = this.currentPath
        ? `${this.currentPath}/${subFolderName}`
        : subFolderName;
      this.openFolder(newPath);
    },
    async openFolder(folderName) {
      try {
        console.log(folderName);
        const files = await apiFolders.getDirectoriesFiles(folderName);

        console.log(files);
        this.selectedFolder = {
          name: folderName,
          files,
        };
        this.isFolderSelected = true;
      } catch (error) {
        console.error(error);
      }
    },
    backToMainFolders() {
      this.isFolderSelected = false;
      this.selectedFolder = { name: "", files: [] };
    },
    handleAddFolder() {
      console.log("Nieuwe map aanmaken");
    },

    handleUpload() {
      console.log("Document uploaden");
    },
  },
};
</script>

<style>
.folder-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
}

.folder-name {
  margin-top: 10px;
  font-weight: bold;
}

.actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.action {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.action v-btn {
  margin-right: 5px;
}
</style>
