<template>
  <Orderbekijken />
</template>

<script>
import Orderbekijken from "@/components/Webshop/Orderbekijken";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Orderbekijken,
  },
  metaInfo: {
    title: "Webwinkel bestelling bekijken",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
