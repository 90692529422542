import { API } from "./api.js";
export default {
  async addMailServer(data) {
    try {
      const response = await API.post("/api/rest/v2/mailcontroller", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getBasicAuth() {
    try {
      const response = await API.get("/api/rest/v2/mailcontroller/basicauth");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
